export default {
  namespaced: true,
  state: {
    queue: [],
    max: 5,
  },
  mutations: {
    PUSH(state, notification) {
      state.queue.push(notification)
      if (state.queue.length > state.max) state.queue.shift()
      else {
        setTimeout(() => {
          state.queue.shift()
        }, 6500)
      }
    },
  },
}
