import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import notify from './notify'
import projects from './projects'
import surveys from './surveys'
import users from './users'
import committees from './committees'
import filter from './filter'
import evaluation from './evaluation'
import tasks from './tasks'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    committees,
    evaluation,
    filter,
    notify,
    projects,
    surveys,
    users,
    tasks
  },
})
