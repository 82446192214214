export default {
  namespaced: true,
  state: {
    isLoading: false,
    shallContentShowOverlay: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, value) {
      state.isLoading = value
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
  },
  actions: {},
}
