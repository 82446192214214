import apiAxios from '@/plugins/axios'


export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('userData')) || {}
  },
  getters: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setRole(state, payload) {
      state.role = payload
    }
  },
  actions: {
    async fetchUsers() {
      try {
        const { data } = await apiAxios.get(`/user/lists?user_id=0`)
        return data.object
      } catch (error) {

      }
    },
    async fetchOne(context, id) {
      try {
        const { data } = await apiAxios.get(`/user/lists?user_id=${id}`)
        return data.object
      } catch (error) {

      }
    },
    async update(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/users/${id}`,  { user: payload } )
        return data
      } catch (error) {

      }
    },
  },
}
