// axios
import axios from 'axios'
import Vue from 'vue'
import { notify } from '@/@core/utils'
import store from '@/store'
import qs from 'qs'
import router from '@/router/index'


const axiosIns = () => (
  axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    timeout: 15000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
)

// eslint-disable-next-line
const setInterceptors = axios => {
  axios.interceptors.request.use(request => {
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`

    if (process.env.NODE_ENV !== 'production') console.log('Request:', request.method, request.url, request.data || ' ')

    return request
  })
  axios.interceptors.response.use(response => {
    if (response.data) {
      if (process.env.NODE_ENV !== 'production') console.log('Response:', response.request.responseURL, response.data)
      if (response.config.method !== 'get' && response.config.url !== 'login') {
        store.commit('notify/PUSH', notify(response.data.messages || response.data.message))
      }
      if (response.config.url === 'login') {
        store.commit('notify/PUSH', notify('Sesión Iniciada'))
      }
    }

    return response
  })

  axios.interceptors.response.use(null, error => {
    if (process.env.NODE_ENV !== 'production') console.log({ ...error })
    const { config, response } = error
    let message = 'Ha ocurrido un error al momento de procesar la solicitud.'
    if (response && response.data) {
      if (process.env.NODE_ENV !== 'production') console.log(response.data)
      if (response.data.message) message = response.data.message
      else if (response.data.messages && response.data.messages.length) {
        message = response.data.messages
      } else if (response.data.errors && response.data.errors.length) message = response.data.errors
    }


    if (config && response && response.status === 401) {
      if (process.env.NODE_ENV !== 'production') console.log(response.data)

      message = response.config.url === 'login' ? message[0] : 'Su sesión ha expirado.'
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')

      /*    // Reset ability
      vm.$ability.update(initialAbility)
 */
      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    if (Array.isArray(message)) {
      message.forEach(item => store.commit('notify/PUSH', notify(item, 'error')))
      message = message[0]
    } else store.commit('notify/PUSH', notify(message, 'error'))

    return Promise.reject(message)
  })

  axios.interceptors.request.use(config => {
    config.paramsSerializer = (params = {}) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      })
    }

    return config
  })
}
const apiAxios = axiosIns()
setInterceptors(apiAxios)

Vue.prototype.$http = apiAxios

export default apiAxios
