<template>
  <v-dialog
    :value="value"
    persistent
    width="300"
    overlay-opacity="0.8"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        Cargando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
}
</script>
