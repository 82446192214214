export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
]

export let defineAbilitiesFor = role => {
  let ability = []
  if (!role) return initialAbility
  if (role === 'admin') {
    ability =  [
      {
        action: 'manage',
        subject: 'all',
      },
    ]
  }
  if (role === 'project_manager') {
    ability =  [
      {
        action: 'read',
        subject: 'home',
      },
      {
        action: 'read',
        subject: 'projects',
      },
      {
        action: 'read',
        subject: 'requests',
      },
      {
        action: 'read',
        subject: 'execute',
      },
    ]
  }
  if (role === 'committee') {
    ability =  [
      {
        action: 'manage',
        subject: 'all',
      },
    ]
  }
  if (role === 'auditor') {
    ability =  [
      {
        action: 'manage',
        subject: 'all',
      },
    ]
  }
  if (role === 'sponsor') {
    ability =  [
      {
        action: 'manage',
        subject: 'all',
      },
    ]
  }
  if (role === 'team_member') {
    ability =  [
      {
        action: 'read',
        subject: 'home',
      },
      {
        action: 'read',
        subject: 'projects',
      },
    ]
  }
  return ability
}


export const _ = undefined
