<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
    <dialog-loading
      v-if="isLoading"
      v-model="isLoading"
    ></dialog-loading>

    <v-snackbar
      value="true"
      v-for="(notify, index) of notifications.value"
      top
      text
      right
      multi-line
      :color="notify.color"
      :timeout="6000"
      :style="'top: ' + 82*index + 'px'"
      :key="index +322"
      class="z"
      >
        {{ notify.message }}
        <template #action="{ attrs }">
          <v-btn
            :color="notify.color"
            icon
            v-bind="attrs"
            @click="notifications.value.splice(index,1)"
          >
            <v-icon>
              {{ icons.mdiCloseCircle }}
            </v-icon>
          </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, reactive, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiCloseCircle } from '@mdi/js'
import store from '@/store'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import DialogLoading from '@/components/widgets/dialog/DialogLoading.vue'

// Dynamic vh

export default {
  components: {
    DialogLoading,
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const notifications = reactive({
      value: [],
    })
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    // computed
    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    const isLoading = computed(() => {
      return store.state.app.isLoading
    })

    const queue = computed(() => store.state.notify.queue)

    // watch
    watch(
      () => queue,
      value => {
        setTimeout(() => {
          notifications.value = value.value
        }, 50)
      },
      { deep: true },
      { immediate: true },
    )

    useDynamicVh()

    return {
      isLoading,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      notifications,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style scoped>
.z {
  z-index: 100000 !important;
}
</style>
