/*eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const token = localStorage.getItem('accessToken')

      if (userData && token) return { name: 'projects-list' }

      return { name: 'auth-login' }
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ejecucion',
    name: 'execute',
    component: () => import('@/views/Execute.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/proyectos/lista',
    name: 'projects-list',
    component: () => import('@/views/projects/projects-list/ProjectsList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/proyectos/nuevo',
    name: 'projects-new',
    component: () => import('@/views/projects/projects-new/ProjectsNew'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/proyectos/:id/:status',
    name: 'projects-id',
    component: () => import('@/views/projects/projects-new/ProjectsNew'),
    meta: {
      layout: 'blank',
    },
    props: true
  },
  {
    path: '/proyectos/comite-evaluativo',
    name: 'evaluation-list',
    component: () => import('@/views/projects/evaluation/evaluation-list/EvaluationList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/proyectos-tareas',
    name: 'tasks-list',
    component: () => import('@/views/projects/tasks/list/TasksList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/proyectos-tareas/nuevo',
    name: 'tasks-new',
    component: () => import('@/views/projects/tasks/new/TaskNew'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/proyectos-tareas/tarea/:id',
    name: 'tasks-id',
    component: () => import('@/views/projects/tasks/new/TaskNew'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/proyectos-seguimiento-tareas',
    name: 'tracking-list',
    component: () => import('@/views/projects/tracking/list/TrackingList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/usuario/lista',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/usuario/:id',
    name: 'user-view',
    component: () => import('@/views/user/user-view/UserView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/encuesta/lista',
    name: 'survey-list',
    component: () => import('@/views/survey/survey-list/SurveyList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/encuesta/nuevo',
    name: 'survey-creator',
    component: () => import('@/views/survey/SurveyCreator'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/encuesta/:id',
    name: 'survey-creator-id',
    component: () => import('@/views/survey/SurveyCreator'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/configuracion/comite/lista',
    name: 'committee-list',
    component: () => import('@/views/committee/committee-list/CommitteeList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/configuracion/comite/nuevo',
    name: 'committee-new',
    component: () => import('@/views/committee/committee-new/CommitteeNew'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/configuracion/comite/:id',
    name: 'committee-id',
    component: () => import('@/views/committee/committee-new/CommitteeNew'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/solicitudes',
    name: 'requests',
    component: () => import('@/views/Requests.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const token = localStorage.getItem('accessToken')

  const isLoggedIn = userData && token
  /* if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  } */

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
