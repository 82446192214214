import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import './plugins/acl'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueCurrencyFilter from 'vue-currency-filter'
import VueQuillEditor from 'vue-quill-editor'
import { VeTable, VePagination, VeIcon, VeLoading, VeLocale } from "vue-easytable"
import "vue-easytable/libs/theme-default/index.css"

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.config.productionTip = false

Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VeTable)
Vue.use(VePagination)
Vue.use(VeIcon)
Vue.use(VeLoading)
Vue.use(VueCurrencyFilter,
  {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 1,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.prototype.$veLoading = VeLoading
Vue.prototype.$veLocale = VeLocale


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
