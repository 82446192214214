import apiAxios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  mutations: {},
  actions: {
    async create(context, payload) {
      try {
        const { data } = await apiAxios.post(`/committees`, { committee: payload })
        return data.object
      } catch (error) {

      }
    },
    async update(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/committees/${id}`, { committee: payload })
        return data.object
      } catch (error) {

      }
    },
    async fetchEvaluation(context) {
      try {
        const id  = context.rootGetters['evaluation/userData'].id
        const { data } = await apiAxios.get(`/committee/evaluate?user_id=${id}`)
        return data.object
      } catch (error) {

      }
    },
    async fetchOne(context, id) {
      try {
        const { data } = await apiAxios.get(`/committee/lists?committee_id=${id}`)
        return data.object
      } catch (error) {

      }
    },
  },
}
