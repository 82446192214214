import apiAxios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchList(context, model) {
      try {
        const { data }= await apiAxios.get(`/${model}`)
        return data
      } catch (error) {

      }
    },
    async fetchProjectList() {
      try {
        const { data }= await apiAxios.get(`/project/lists?user_id=0`)
          return data.object
      } catch (error) {

      }
    },
    async fetchSelectLists() {
      try {
        const { data }= await apiAxios.get(`/projects/select_lists`)
          return data
      } catch (error) {

      }
    },
    async fetchProjectReport(context, id) {
      try {
        const { data }= await apiAxios.get(`/project/reports?project_id=${id}`)
          return JSON.parse(data.object[0].reportproject)
      } catch (error) {

      }
    },
    async fetchOne(context, id) {
      try {
        const { data } = await apiAxios.get(`/projects/${id}`)
        return data
      } catch (error) {

      }
    },
    async fetchOneFormulations(context, id) {
      try {
        const { data } = await apiAxios.get(`/project_formulations/${id}`)
        return data
      } catch (error) {

      }
    },
    async create(context, payload) {
      try {
        const { data } = await apiAxios.post(`/${payload.model}`,  payload.params )
        return data
      } catch (error) {

      }
    },
    async createProject(context, payload) {
      try {
        const { data } = await apiAxios.post(`/projects`,  { project: payload } )
        return data
      } catch (error) {

      }
    },
    async createProjectFormulations(context, payload) {
      try {
        const { data } = await apiAxios.post(`/project_formulations`,  { project_formulation: payload } )
        return data
      } catch (error) {

      }
    },
    async updateProject(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/projects/${id}`,  { project: payload } )
        return data
      } catch (error) {

      }
    },
    async updateProjectFormulations(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/project_formulations/${id}`,  { project_formulation: payload } )
        return data
      } catch (error) {

      }
    },
    async setOne(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.get(`/${payload.model}/${id}`)
        return data
      } catch (error) {

      }
    },
    async update(context, payload) {
      try {
        const id = payload.id
        const data = await apiAxios.put(`/${payload.model}/${id}`,  payload.params )
        return data
      } catch (error) {

      }
    },
  },
}
