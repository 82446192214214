import apiAxios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createSurvey(context, payload) {
      try {
        const { data } = await apiAxios.post(`/surveys`,  { survey: payload } )
        return data
      } catch (error) {

      }
    },
    async fetchSurveys() {
      try {
        const { data } = await apiAxios.get(`/survey/lists?survey_id=0`)
        return data.object
      } catch (error) {

      }
    },
    async fetchOne(context, id) {
      try {
        const { data } = await apiAxios.get(`/surveys/${id}`)
        return data
      } catch (error) {

      }
    },
    async updateSurvey(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/surveys/${id}`,  { survey: payload } )
        return data
      } catch (error) {

      }
    },
  },
}
