import {
  mdiCogs, mdiFolderOpen, mdiGestureTapHold, mdiHome, mdiTools,
} from '@mdi/js'

export default [
  {
    title: 'DASHBOARD',
    icon: mdiHome,
    to: 'home',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'CONFIGURACIÓN',
    icon: mdiTools,
    children: [
      {
        title: 'Usuario',
        to: 'user-list',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'Encuesta',
        to: 'survey-list',
        resource: 'config',
        action: 'read',
      },
      {
        title: 'Comité',
        to: 'committee-list',
        resource: 'config',
        action: 'read',
      },
    ]
  },
  {
    title: 'PROYECTOS',
    icon: mdiFolderOpen,
    children: [
      {
        title: 'Proyectos',
        to: 'projects-list',
        resource: 'projects',
        action: 'read',
      },
      {
        title: 'Comité Evaluativo',
        to: 'evaluation-list',
        resource: 'projects',
        action: 'read',
      },
      {
        title: 'Tareas',
        to: 'tasks-list',
        resource: 'projects',
        action: 'read',
      },
      {
        title: 'Seguimiento a tareas',
        to: 'tracking-list',
        resource: 'projects',
        action: 'read',
      },
    ],
  },
  {
    title: 'SOLICITUDES',
    icon: mdiGestureTapHold,
    to: 'requests',
    resource: 'requests',
    action: 'read',
  },
  {
    title: 'EJECUCIÓN',
    target: '_blank',
    icon: mdiCogs,
    to: 'execute',
    resource: 'execute',
    action: 'read',
  },
]
