const initialState = () => {
  return {
    filterField: localStorage.getItem('filterField') ? JSON.parse(localStorage.getItem('filterField')) : '',
    filterOperator: localStorage.getItem('filterOperator') ? JSON.parse(localStorage.getItem('filterOperator')) : '',
    filterTerm: localStorage.getItem('filterTerm') ? JSON.parse(localStorage.getItem('filterTerm')) : '',
    filterTerm2: localStorage.getItem('filterTerm2') ? JSON.parse(localStorage.getItem('filterTerm2')) : '',
    filterLookupValue: '',
    route: localStorage.getItem('route') ? JSON.parse(localStorage.getItem('route')) : '',
  }
}

const state = initialState()

const mutations = {

  setFilterField(state, payload) {
    state.filterField = payload
    localStorage.setItem('filterField', JSON.stringify(state.filterField))
  },
  setRoute(state, payload) {
    state.route = payload
    localStorage.setItem('route', JSON.stringify(state.route))
  },
  setFilterOperator(state, payload) {
    state.filterOperator = payload
    localStorage.setItem('filterOperator', JSON.stringify(state.filterOperator))
  },
  setFilterTerm(state, payload) {
    state.filterTerm = payload
    localStorage.setItem('filterTerm', JSON.stringify(state.filterTerm))
  },
  setFilterTerm2(state, payload) {
    state.filterTerm2 = payload
    localStorage.setItem('filterTerm2', JSON.stringify(state.filterTerm2))
  },
  setFilterLookupValue(state, payload) {
    state.filterLookupValue = payload
  },
  resetTerms(state) {
    localStorage.removeItem('filterTerm')
    localStorage.removeItem('filterTerm2')
  },
  removeState(state) {
    localStorage.removeItem('filterField')
    localStorage.removeItem('filterOperator')
    localStorage.removeItem('filterTerm')
    localStorage.removeItem('filterTerm2')
    localStorage.removeItem('route')
    Object.assign(state, initialState())
  }

}

export default {
  namespaced: true,
  state,
  mutations
}
