import apiAxios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchList(context, model) {
      try {
        const { data }= await apiAxios.get(`/${model}`)
        return data
      } catch (error) {

      }
    },
    async fetchTaskList() {
      try {
        const { data }= await apiAxios.get(`/task/lists`)
          return JSON.parse(data.object[0].listtasks)
      } catch (error) {

      }
    },
    async fetchTaskResources() {
      try {
        const { data }= await apiAxios.get(`/task_resources`)
          return JSON.parse(data.object[0].taskresources)
      } catch (error) {

      }
    },
    async fetchProjectReport(context, id) {
      try {
        const { data }= await apiAxios.get(`/project/reports?project_id=${id}`)
          return JSON.parse(data.object[0].reportproject)
      } catch (error) {

      }
    },
    async fetchOne(context, id) {
      try {
        const { data } = await apiAxios.get(`/tasks/${id}`)
        return data
      } catch (error) {

      }
    },
    async fetchOneFormulations(context, id) {
      try {
        const { data } = await apiAxios.get(`/project_formulations/${id}`)
        return data
      } catch (error) {

      }
    },
    async create(context, payload) {
      try {
        const { data } = await apiAxios.post(`/${payload.model}`,  payload.params )
        return data
      } catch (error) {

      }
    },
    async createTask(context, payload) {
      try {
        const { data } = await apiAxios.post(`/tasks`,  { task: payload } )
        return data
      } catch (error) {

      }
    },
    async createProjectFormulations(context, payload) {
      try {
        const { data } = await apiAxios.post(`/project_formulations`,  { project_formulation: payload } )
        return data
      } catch (error) {

      }
    },
    async updateTask(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/tasks/${id}`,  { task: payload } )
        return data
      } catch (error) {

      }
    },
    async updateProjectFormulations(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.put(`/project_formulations/${id}`,  { project_formulation: payload } )
        return data
      } catch (error) {

      }
    },
    async setOne(context, payload) {
      try {
        const id = payload.id
        const { data } = await apiAxios.get(`/${payload.model}/${id}`)
        return data
      } catch (error) {

      }
    },
    async update(context, payload) {
      try {
        const id = payload.id
        const data = await apiAxios.put(`/${payload.model}/${id}`,  payload.params )
        return data
      } catch (error) {

      }
    },
  },
}
